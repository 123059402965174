<template>
  <div>
    <ApprovalRequest
      v-for="approval in pendingApprovals"
      :key="approval.id"
      :request="approval"
      :display-attestation="displayAttestation"
      class="mb-8"
      @approved="$emit('approved')"
      @rejected="$emit('rejected')"
    />
  </div>
</template>

<script>
import ApprovalRequest from "./ApprovalRequest";

export default {
  components: {
    ApprovalRequest
  },
  props: {
    pendingApprovals: {
      type: Array,
      default: () => []
    },
    displayAttestation: {
      type: Boolean,
      default: false
    }
  },
  emits: ["approved", "rejected"]
};
</script>
