<template>
  <VCard title-class="bg-theme-5">
    <template #title>
      <div class="flex justify-between items-center gap-3">
        <VImage :src="avatar" :name="name" :width="12" :height="12" />

        <div>
          <div class="text-base font-medium">
            {{ name }}
          </div>

          <div class="label text-sm font-light">
            {{ username }}
          </div>
        </div>
      </div>
    </template>

    <template #title-actions>
      <VChip
        :text="$t(`app.${request?.status}`)"
        :class="getStatusColor(request?.status)"
      />
    </template>

    <template #content>
      <div class="grid grid-cols-2 gap-4">
        <VLine :label="$t('app.trainings')">
          <template #value>
            <div class="cursor-pointer" @click="onClickRedirectToTraining">
              {{ getText(training?.texts, "name") }}
            </div>
          </template>
        </VLine>

        <VLine v-if="!hideAttestation" :label="$t('app.attestations')">
          <template #value>
            <div class="cursor-pointer" @click="onClickRedirectToAttestation">
              {{ getText(attestation?.texts, "name") }}
            </div>
          </template>
        </VLine>

        <VLine
          class="col-span-2"
          :label="$t('app.requested_on')"
          :value="formatDate({ date: request.requested_on })"
        />

        <slot name="content" />

        <!-- LEARNER COMMENT -->
        <div v-if="request.learner_comments" class="col-span-2">
          <VLine :label="$t('app.learner_comment')" />

          {{ request.learner_comments }}
        </div>

        <!-- INSTRUCTOR COMMENT -->
        <VComment
          :model-value="comment"
          class="col-span-2"
          :label="$t('app.instructor_comment')"
          @update:modelValue="$emit('update:modelValue', $event)"
        />
      </div>
    </template>

    <template #actions>
      <slot name="actions" />
    </template>
  </VCard>
</template>

<script>
import { computed } from "vue";
import { useRouter } from "vue-router";
// Composables
import useDisplay from "@/composables/useDisplay";
import useTexts from "@/composables/useTexts";
import useColor from "@/composables/useColor";
// Components
import VCard from "@/components/VCard";
import VLine from "@/components/VLine";
import VChip from "@/components/VChip";
import VImage from "@/components/VImage";
import VComment from "@/components/inputs/VComment";

export default {
  components: {
    VCard,
    VComment,
    VChip,
    VImage,
    VLine
  },
  props: {
    request: {
      type: Object,
      default: () => ({})
    },
    comment: {
      type: String,
      default: ""
    },
    hideAttestation: {
      type: Boolean,
      default: false
    }
  },
  emit: ["update:modelValue"],
  setup(props) {
    // Misc
    const router = useRouter();

    // Composables
    const { getText } = useTexts();
    const { getStatusColor } = useColor();
    const { formatDate, getAvatarURL } = useDisplay();

    // Computed
    const attestation = computed(() => props.request?.attestation || {});
    // eslint-disable-next-line
    const training = computed(() => props.request?.registration?.training || {});
    const learner = computed(() => props.request?.registration?.learner || {});
    // eslint-disable-next-line
    const avatar = computed(() => getAvatarURL(learner.value?.avatar?.view_path));

    const name = computed(() => {
      const { firstname, lastname } = learner.value;

      return firstname && lastname ? `${firstname} ${lastname}` : "";
    });

    const username = computed(() => {
      const { username } = learner.value;

      return username ? `@${username}` : "";
    });

    // Methods
    const onClickRedirectToTraining = () => {
      router.push({
        name: "teaching-trainings-update",
        params: {
          id: training.value.id
        }
      });
    };

    const onClickRedirectToAttestation = () => {
      router.push({
        name: "teaching-attestations-update",
        params: {
          id: attestation.value.id
        }
      });
    };

    return {
      onClickRedirectToTraining,
      onClickRedirectToAttestation,
      attestation,
      name,
      username,
      training,
      avatar,
      // useDisplay
      formatDate,
      // useTexts
      getText,
      // useColor
      getStatusColor
    };
  }
};
</script>
