import { computed } from "vue";
import { useRouter } from "vue-router";
// Composables
import useRequest from "@/composables/useRequest";

export default function useAttestationRequest(props, context, args) {
  const { instructor_comments, file_ids, decision } = args;

  // Misc
  const router = useRouter();

  // Composables
  const { request: approveRequest, isRequesting: isApproving } = useRequest();
  // eslint-disable-next-line
  const { request: rejectRequest, isRequesting: isRejecting } = useRequest();
  const { request: submitRequest, isRequesting: isSubmitting } = useRequest();

  // Computed
  const id = computed(() => {
    return props.request.id;
  });

  const reject = async () => {
    await rejectRequest({
      endpoint: "teaching.attestations.submission.submit",
      pathParams: { id: id.value },
      data: {
        instructor_comments: instructor_comments.value,
        decision: "Rejected"
      }
    });
    context.emit("rejected");
  };

  const approve = async () => {
    await approveRequest({
      endpoint: "teaching.attestations.submission.submit",
      pathParams: { id: id.value },
      data: {
        instructor_comments: instructor_comments.value,
        decision: "Approved"
      }
    });
    context.emit("approved");
  };

  const view = () => {
    router.push({
      name: "teaching-attestations-details",
      params: { id: id.value }
    });
  };

  const submit = async () => {
    await submitRequest({
      endpoint: "teaching.attestations.submission.update",
      pathParams: { id: id.value },
      data: {
        instructor_comments: instructor_comments.value,
        decision: decision.value,
        file_ids: file_ids.value.toString()
      }
    });
    context.emit("submitted");
  };

  return {
    isRejecting,
    isApproving,
    isSubmitting,
    reject,
    approve,
    view,
    submit
  };
}
