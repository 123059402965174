<template>
  <div>
    <VTitle :title="$t('app.overview')" class="section-title" />

    <div v-if="isLoading" class="flex justify-center">
      <LoadingIcon icon="three-dots" class="w-8 h-8" />
    </div>

    <div v-else class="grid grid-cols-3 gap-8">
      <div class="col-span-3 xl:col-span-2">
        <VAlert v-if="!hasRequests" :text="$t('app.no_attestation_requests')" />

        <div v-else>
          <VTitle :title="$t('app.requests', 2)" class="overview-title" />

          <SubmissionRequest
            v-for="submission in data.submission_requests"
            :key="submission.id"
            hide-attestation
            :request="submission"
            @submitted="getOverview"
          />

          <ApprovalRequests
            class="mt-8"
            :pending-approvals="data.pending_approvals"
            @approved="getOverview"
            @rejected="getOverview"
          />
        </div>
      </div>

      <div class="col-span-3 xl:col-span-1">
        <VStatisticsCard
          :title="$t('app.statistics')"
          :statistics="data.statistics"
        />

        <LatestSubmissions
          class="mt-8"
          :latest-submissions="data.latest_submissions"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, computed } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useTexts from "@/composables/useTexts";
import useAttestation from "@/composables/useAttestation";
import useRequest from "@/composables/useRequest";
// Components
import VTitle from "@/components/VTitle";
import VAlert from "@/components/VAlert";
import ApprovalRequests from "./ApprovalRequests";
import SubmissionRequest from "./SubmissionRequest";
import LatestSubmissions from "./LatestSubmissions";
import VStatisticsCard from "@/components/VStatisticsCard";

export default {
  components: {
    VTitle,
    VAlert,
    LatestSubmissions,
    ApprovalRequests,
    VStatisticsCard,
    SubmissionRequest
  },
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    attestation: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    // Misc
    const { t } = useI18n();

    // Composables
    const { isRequesting: isLoading, request: read } = useRequest();
    const { endpoint } = useAttestation();
    const { getText } = useTexts();

    // Data
    const data = ref({});

    // Computed
    const hasRequests = computed(() => {
      if (!data.value) {
        return false;
      }

      const { pending_approvals, submission_requests } = data.value;

      if (!pending_approvals || !submission_requests) {
        return false;
      }

      return pending_approvals.length > 0 || submission_requests.length > 0;
    });

    // Computed
    const documentTitle = computed(() => {
      // eslint-disable-next-line
      const title = `${t("app.overview")} - ${t("app.attestations", 2)} - ${t("app.teaching")}`;

      const name = getText(props.attestation?.texts, "name");
      if (name) {
        return `${name} - ${title}`;
      }

      return title;
    });

    // Methods
    const getOverview = async () => {
      const response = await read({
        endpoint: `${endpoint}.overview`,
        pathParams: {
          id: props.id
        },
        showToaster: false
      });

      data.value = response?.payload?.data;
    };

    // Lifecycle Hooks
    onMounted(() => {
      getOverview();
    });

    return {
      documentTitle,
      hasRequests,
      isLoading,
      data,
      getOverview
    };
  }
};
</script>
