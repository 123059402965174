<template>
  <div>
    <VTitle :title="$t('app.latest_submissions')" class="overview-title" />

    <VCard>
      <template #content>
        <VAlert
          v-if="latestSubmissions.length === 0"
          :text="$t('app.no_latest_submissions')"
        />

        <div v-else>
          <!-- @TODO: redirect to submission? -->
          <div
            v-for="(submission, index) in latestSubmissions"
            :key="submission.id"
            class="flex items-center cursor-pointer"
            :class="{ 'mb-4': index !== latestSubmissions.length - 1 }"
          >
            <div class="border-l-2 border-theme-1 pl-4">
              <div class="font-medium">
                {{ getName(submission) }}
              </div>

              <div class="text-gray-600">
                {{ formatDate({ date: submission.updated_at }) }}
              </div>
            </div>

            <VChip
              :text="$t(`app.${submission.status}`)"
              class="ml-auto"
              :class="getStatusColor(submission.status)"
            />
          </div>
        </div>
      </template>
    </VCard>
  </div>
</template>

<script>
// Composables
import useDisplay from "@/composables/useDisplay";
import useColor from "@/composables/useColor";
// Components
import VCard from "@/components/VCard";
import VTitle from "@/components/VTitle";
import VChip from "@/components/VChip";
import VAlert from "@/components/VAlert";

export default {
  components: {
    VTitle,
    VCard,
    VChip,
    VAlert
  },
  props: {
    latestSubmissions: {
      type: Array,
      default: () => []
    }
  },
  setup() {
    // Composables
    const { getStatusColor } = useColor();
    const { formatDate } = useDisplay();

    // Methods
    const getName = submission => {
      if (!submission?.registration?.learner) {
        return "";
      }

      const { firstname, lastname } = submission.registration.learner;
      return `${firstname} ${lastname}`;
    };

    return {
      getName,
      // useDisplay
      formatDate,
      // useColor
      getStatusColor
    };
  }
};
</script>
