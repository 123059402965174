<template>
  <PendingRequest v-model="instructor_comments" :request="request">
    <template #content>
      <div v-if="files.length > 0" class="col-span-2 flex items-center">
        <VLine :label="$t('app.files', 2)" />

        <FileItems :files="files" />
      </div>
    </template>

    <template v-if="canApprove" #actions>
      <div class="flex justify-end">
        <VButton
          :label="$t('app.reject')"
          :is-loading="isRejecting"
          icon="fal fa-times-circle"
          class="btn-danger mr-2"
          @click="onClickReject"
        />

        <VButton
          :label="$t('app.approve')"
          :is-loading="isApproving"
          icon="fal fa-check-circle"
          class="btn-success"
          @click="onClickApprove"
        />
      </div>
    </template>
  </PendingRequest>
</template>

<script>
import { computed, ref } from "vue";
// Composables
import useAttestationRequest from "@/composables/useAttestationRequest";
// Components
import VButton from "@/components/VButton";
import VLine from "@/components/VLine";
import FileItems from "@/components/FileItems";
import PendingRequest from "./PendingRequest";

export default {
  components: {
    VButton,
    VLine,
    FileItems,
    PendingRequest
  },
  props: {
    request: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ["approved", "rejected"],
  setup(props, context) {
    // Data
    const instructor_comments = ref(props.request.instructor_comments);

    // Computed
    const files = computed(() => {
      return props.request?.files || [];
    });

    const canApprove = computed(() => {
      const hasCanApprove = "can_approve" in props.request;
      return !hasCanApprove || (hasCanApprove && props.request.can_approve);
    });

    // Composables
    const {
      view,
      approve,
      reject,
      isApproving,
      isRejecting
    } = useAttestationRequest(props, context, { instructor_comments });

    return {
      canApprove,
      files,
      instructor_comments,
      // useAttestationRequests
      isApproving,
      isRejecting,
      onClickApprove: approve,
      onClickReject: reject,
      onClickViewAttestation: view
    };
  }
};
</script>
