<template>
  <PendingRequest v-model="instructor_comments" :request="request">
    <template #content>
      <VFile v-model="file_ids" multiple class="flex items-center">
        <template #label="{ id }">
          <VLine :for="id" :label="$t('app.files', 2)" />
        </template>
      </VFile>

      <VSwitch
        v-model="decision"
        class="flex items-center"
        true-value="Approved"
        false-value="Rejected"
      >
        <template #label="{ id }">
          <VLine :for="id" :label="$t('app.upload_as')" />
        </template>
      </VSwitch>
    </template>

    <template v-if="canSubmit" #actions>
      <div class="justify-end flex">
        <VButton
          v-if="canSubmit"
          :is-loading="isSubmitting"
          class="btn-primary"
          :label="$t('app.submit')"
          @click="onClickSubmit"
        />
      </div>
    </template>
  </PendingRequest>
</template>

<script>
import { ref, computed } from "vue";
// Composables
import useAttestationRequest from "@/composables/useAttestationRequest";
// Components
import VButton from "@/components/VButton";
import VLine from "@/components/VLine";
import VFile from "@/components/inputs/VFile";
import VSwitch from "@/components/inputs/VSwitch";
import PendingRequest from "./PendingRequest";

export default {
  components: {
    VButton,
    VFile,
    VLine,
    VSwitch,
    PendingRequest
  },
  props: {
    request: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ["submitted"],
  setup(props, context) {
    // Computed
    const canSubmit = computed(() => {
      const hasCanSubmit = "can_submit" in props.request;
      return !hasCanSubmit || (hasCanSubmit && props.request.can_submit);
    });

    // Data
    const instructor_comments = ref(props.request.instructor_comments);
    const file_ids = ref(props.request.files);
    const decision = ref("Approved");

    // Composables
    const { view, submit, isSubmitting } = useAttestationRequest(
      props,
      context,
      {
        instructor_comments,
        file_ids,
        decision
      }
    );

    return {
      canSubmit,
      instructor_comments,
      file_ids,
      decision,
      // useAttestationRequests
      isSubmitting,
      onClickSubmit: submit,
      onClickViewAttestation: view
    };
  }
};
</script>
